import { formatDateTime } from '@/helpers/formatDateTime';
import { getArticleLink } from '@/helpers/getArticleLink';
import classnames from 'classnames';
import { getAssetArticleLink } from 'helpers/getAssetArticleLink';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useMemo, useState } from 'react';
import { Article, AssetArticle } from 'types';

interface HighlightArticlesProps {
    articles: HighlightArticleItem[];
    isMobile: Boolean;
    isTablet: Boolean;
}

enum ArticleTabs {
    LatestArticles = 'latest articles',
    Featured = 'featured',
    Popular = 'popular',
    CompanyBlog = 'company blog',
}

export type HighlightArticleItemType = 'article' | 'assetArticle';
export interface HighlightArticleItem {
    type: HighlightArticleItemType;
    article: Article | AssetArticle;
    isPinned?: boolean;
}

const MAX_ARTICLE_LIST = 5;

export const HighlightArticles: React.FC<HighlightArticlesProps> = ({
    isMobile,
    isTablet,
    articles,
}: HighlightArticlesProps) => {
    const selectedTab = useMemo(() => ArticleTabs.LatestArticles, [ArticleTabs]);
    // const [selectedTab] = useState<ArticleTabs>(ArticleTabs.LatestArticles)
    const [selectedArticle, setSelectedArticle] = useState<HighlightArticleItem | null>(articles[0] || null);
    const [articleList, setArticleList] = useState<HighlightArticleItem[]>(
        [
            ...articles.filter(a => a.isPinned),
            ...articles
                .filter(a => !a.isPinned)
                .sort(
                    (a, b) =>
                        new Date(b.article.published_at || '').getTime() -
                        new Date(a.article.published_at || '').getTime(),
                ),
        ].slice(0, MAX_ARTICLE_LIST),
    );

    const { t } = useTranslation('common');

    useEffect(() => {
        let articleItems = [];

        switch (selectedTab) {
            case ArticleTabs.LatestArticles:
                articleItems = [
                    ...articles.filter(a => a.isPinned),
                    ...articles
                        .filter(a => !a.isPinned)
                        .sort(
                            (a, b) =>
                                new Date(b.article.published_at || '').getTime() -
                                new Date(a.article.published_at || '').getTime(),
                        ),
                ].slice(0, MAX_ARTICLE_LIST);
                setArticleList(articleItems);
                setSelectedArticle(articleItems[0]);
                break;
            case ArticleTabs.Featured:
                // TODO: implement filter/sorting by Featured
                articleItems = articles
                    .sort((a, b) => a.article.title.toLowerCase().localeCompare(b.article.title.toLowerCase()))
                    .slice(0, MAX_ARTICLE_LIST);
                setArticleList(articleItems);
                setSelectedArticle(articleItems[0]);
                break;
            case ArticleTabs.Popular:
                // TODO: implement filter/sorting by Popular
                articleItems = articles
                    .sort((a, b) => b.article.title.toLowerCase().localeCompare(a.article.title.toLowerCase()))
                    .slice(0, MAX_ARTICLE_LIST);
                setArticleList(articleItems);
                setSelectedArticle(articleItems[0]);
                break;
            case ArticleTabs.CompanyBlog:
                // TODO: implement filter/sorting by CompanyBlog
                articleItems = articles
                    .sort((a, b) =>
                        a.article.description.toLowerCase().localeCompare(b.article.description.toLowerCase()),
                    )
                    .slice(0, MAX_ARTICLE_LIST);
                setArticleList(articleItems);
                setSelectedArticle(articleItems[0]);
                break;
        }
    }, [articles, selectedTab]);

    const renderArticleList = useMemo(() => {
        return (
            <>
                {articleList.map((article, index) => {
                    const cnArticle = classnames('rounded p-2 my-1 text-base font-semibold', {
                        'bg-text-color-10 border-l-primary-cta-color-60 border-l-4':
                            article.article.id === selectedArticle?.article.id &&
                            article.type === selectedArticle?.type,
                    });

                    return (
                        <div key={index} className={cnArticle} onMouseEnter={() => setSelectedArticle(article)}>
                            {article.type === 'article' ? (
                                <Link href={getArticleLink(article.article)} className="line-clamp-2">
                                    {article.article.title}
                                </Link>
                            ) : (
                                <Link
                                    href={getAssetArticleLink(article.article as AssetArticle)}
                                    className="line-clamp-2">
                                    {article.article.title}
                                </Link>
                            )}
                            <div className="flex justify-between">
                                <div className="flex gap-2">
                                    <div className="text-text-color-60">
                                        {formatDateTime(article.article.published_at)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }, [articleList, selectedArticle]);

    const cnLayout = classnames(
        'w-full border border-divider-color-20 rounded shadow pl-5 py-5 pr-5 mx-[18px] sm:mx-6 lg:mx-0 flex',
        {
            'flex-col': isTablet,
        },
    );

    const cnHighlight = classnames('4xl:w-full 3xl:w-full 2xl:w-[907px] xl:w-[570px]', {
        'ml-5': !isTablet,
    });

    const cnTabs = classnames('3xl:max-w-[452px] 2xl:max-w-[452px] xl:max-w-[452px] lg:max-w-[452px] w-full', {
        'mt-5': isTablet && !isMobile,
        'mt-0': isMobile,
    });

    return articles.length ? (
        <div className={cnLayout}>
            {(!isTablet && (
                <div className={cnTabs}>
                    <div className="flex flex-col w-full">{renderArticleList}</div>
                </div>
            )) ||
                null}
            {!isMobile ? (
                <div className={cnHighlight}>
                    <div className="relative flex flex-col">
                        <div className="rounded">
                            <Image
                                src={selectedArticle!.article.image_url || '/images/empty.webp'}
                                alt={t('page.landing.articles.imageAlt', { title: selectedArticle!.article.title })}
                                height={500}
                                width={900}
                                className="w-full h-auto rounded-md object-cover"
                                loading="eager"
                            />
                        </div>
                        <div className="absolute mt-3 ml-3 px-2 py-0 rounded flex gap-2 items-start" />
                        <div className="absolute bottom-0 w-full bg-main-background-color-opacity/85 p-5">
                            <h3 className="text-lg font-metro-bold">{selectedArticle!.article.title}</h3>
                            <div>
                                {selectedArticle!.article.author && (
                                    <>
                                        <span className="text-text-color-60">{selectedArticle!.article.author}</span>
                                        <span className="text-text-color-60 mx-2">&#8226;</span>
                                    </>
                                )}
                                <span className="text-text-color-60">
                                    {formatDateTime(selectedArticle!.article.published_at)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={cnHighlight}>
                    <div className="relative flex flex-col">
                        <div className="rounded">
                            <Image
                                src={selectedArticle!.article.image_url || '/images/empty.webp'}
                                alt={t('page.landing.articles.imageAlt', { title: selectedArticle!.article.title })}
                                height={350}
                                width={600}
                                className="w-full h-auto rounded-md object-cover"
                                loading="eager"
                            />
                        </div>
                    </div>
                </div>
            )}
            {(isTablet && (
                <div className={cnTabs}>
                    <div className="flex flex-col w-full">{renderArticleList}</div>
                </div>
            )) ||
                null}
        </div>
    ) : null;
};
