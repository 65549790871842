import { renderPrice } from '@/helpers/renderPrice';
import { Decimal } from '@/web-sdk/components/Decimal';
import { getConfigs } from '@/web-sdk/configs/app';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useMemo } from 'react';
import { Line, LineChart, YAxis } from 'recharts';
import { Asset } from 'types';

const LandingBlockHeader = dynamic(() =>
    import('@/components/Landing/BlockHeader').then(mod => mod.LandingBlockHeader),
);
const RandomBanner = dynamic(() => import('@/components/Landing/RandomBanner').then(mod => mod.RandomBanner));

interface MarketStatsProps {
    topGainers: Asset[];
    topLosers: Asset[];
    marketCharts: { asset: string; data: MarketStatKline[] }[];
    isMobile: boolean;
    isTablet: boolean;
    randomBannerIndex: number;
}

interface MarketOrderType {
    id: string;
    name: string;
    description: string;
}

export interface MarketStatKline {
    timestamp: number;
    price: number;
}

export const MarketStats: React.FC<MarketStatsProps> = ({
    topGainers,
    topLosers,
    marketCharts,
    isMobile,
    isTablet,
    randomBannerIndex,
}: MarketStatsProps): JSX.Element => {
    const { t } = useTranslation('common');
    const { platformCharSymbol } = getConfigs();

    const MarketOrders: MarketOrderType[] = [
        {
            id: 'top_gainers',
            name: t('page.landing.marketStats.topGainers'),
            description: t('page.landing.marketStats.topGainers.description'),
        },
        {
            id: 'top_losers',
            name: t('page.landing.marketStats.topLosers'),
            description: t('page.landing.marketStats.topLosers.description'),
        },
    ];

    const renderChart = useCallback((marketChart: MarketStatKline[], isPriceDrop: boolean) => {
        const minValue = Math.min(...marketChart.map(item => item.price));
        const maxValue = Math.max(...marketChart.map(item => item.price));
        const strokeColor = isPriceDrop ? '#B01212' : '#00632B';

        return (
            <LineChart width={73} height={46} data={marketChart}>
                <YAxis type="number" domain={[minValue, maxValue]} hide />
                <Line type="linear" dataKey="price" stroke={strokeColor} dot={false} strokeWidth={1} />
            </LineChart>
        );
    }, []);

    const renderMarketItem = useCallback(
        (key: number, asset: Asset) => {
            const isPriceDrop = !!((asset.price_change_24h || 0) < 0);

            const cnContainer = classnames('flex w-full flex-grow px-2 py-1 justify-between rounded-md', {
                'bg-bid-10': !isPriceDrop,
                'bg-ask-10': isPriceDrop,
            });

            const cnPrice = classnames('font-bold text-base', {
                'text-bid-90': !isPriceDrop,
                'text-ask-90': isPriceDrop,
            });

            const chevronIcon = isPriceDrop ? (
                <ChevronDownIcon width={14} className="text-ask-40 stroke-[2px]" />
            ) : (
                <ChevronUpIcon width={14} className="text-bid-40 stroke-[2px]" />
            );

            const cnPercentage = classnames('text-sm ml-2', {
                'text-bid-60': !isPriceDrop,
                'text-ask-60': isPriceDrop,
                'font-semibold': !isMobile,
                'font-medium': isMobile,
            });

            const marketChart = (marketCharts || []).find(m => m.asset === asset.cg_api_id)?.data;

            const renderDesktopMarketItem = () => {
                return (
                    <Link key={key} href={`/asset/${asset.code}`} className="flex gap-2 justify-between my-4 ml-4">
                        <div className="flex items-center flex-grow w-full">
                            <div className="flex items-center mx-1.5">
                                <Image
                                    alt={asset.code}
                                    src={asset.logo ? asset.logo : '/images/icons/missing_small.webp'}
                                    width={30}
                                    height={30}
                                    className="object-contain"
                                    loading="eager"
                                />
                            </div>
                            <div className="font-semibold text-sm text-neutral-control-layer-color-100">
                                {asset.name}
                            </div>
                        </div>
                        <div className={cnContainer}>
                            <div>
                                {marketChart && marketChart.length ? renderChart(marketChart, isPriceDrop) : null}
                            </div>
                            <div className="flex flex-col items-end">
                                <div className={cnPrice}>
                                    {platformCharSymbol}
                                    {renderPrice(asset.price)}
                                </div>
                                <div className="flex ">
                                    {chevronIcon}
                                    <span className={cnPercentage}>
                                        {+(asset.price_change_24h || 0) > 0 ? '+' : ''}
                                        {Decimal.format(String(asset.price_change_24h), 2, ',')}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            };

            const renderMobileMarketItem = () => {
                return (
                    <Link key={key} href={`/asset/${asset.code}`} className="flex gap-2 justify-between my-5 ml-5">
                        <div className="flex items-center flex-grow w-full">
                            <div className="flex items-center mx-1.5">
                                <Image
                                    alt={asset.code}
                                    src={asset.logo ? asset.logo : '/images/icons/missing_small.webp'}
                                    width={16}
                                    height={16}
                                    className="object-contain"
                                    loading="eager"
                                />
                            </div>
                            <div className="font-semibold text-xs text-neutral-control-layer-color-100">
                                {asset.name}
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="font-semibold text-xs">
                                {platformCharSymbol}
                                {renderPrice(asset.price)}
                            </div>
                            <div className={cnContainer}>
                                <div className="flex ">
                                    {chevronIcon}
                                    <span className={cnPercentage}>
                                        {+(asset.price_change_24h || 0) > 0 ? '+' : ''}
                                        {Decimal.format(String(asset.price_change_24h), 2, ',')}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            };

            return isMobile ? renderMobileMarketItem() : renderDesktopMarketItem();
        },
        [marketCharts, isMobile],
    );

    const renderMarketList = useCallback(
        (key: number, marketOrderType: MarketOrderType, assets: Asset[]) => {
            const cnTitle = classnames('font-bold text-text-color-100 m-2 mt-3', {
                'text-lg': !isMobile,
                'text-base': isMobile,
            });

            const cnContaier = classnames('flex-grow w-full ', {
                'min-w-[380px] lg:min-w-[400px]': !isMobile,
                'min-w-[300px]': isMobile,
            });

            return (
                <div key={key} className={cnContaier}>
                    <div className="border border-divider-color-20 rounded-md pr-4 pl-1 py-1">
                        <div className={cnTitle}>
                            {marketOrderType.id === 'top_gainers' ? '🚀' : '🚨'} {marketOrderType.name}
                        </div>
                        {(assets || []).map((asset, index) => {
                            return renderMarketItem(index, asset);
                        })}
                    </div>
                </div>
            );
        },
        [renderMarketItem, isMobile],
    );

    const renderMarketStats = useMemo(() => {
        const cnContainer = classnames('flex w-fit gap-6 justify-between', {
            'mr-9': isTablet && !isMobile,
            'flex-col w-full': isMobile,
        });

        return (
            <div className="w-full overflow-scroll no-scrollbar pl-0 pr-[6px] md:pr-3 lg:px-0">
                <div className={cnContainer}>
                    {MarketOrders.map((marketOrderType, index) => {
                        switch (marketOrderType.id) {
                            case 'top_gainers':
                                return renderMarketList(index, marketOrderType, topGainers);
                            case 'top_losers':
                                return renderMarketList(index, marketOrderType, topLosers);
                            default:
                                return null;
                        }
                    })}
                    <RandomBanner isMobile={isMobile} isTablet={isTablet} randomBannerIndex={randomBannerIndex} />
                </div>
            </div>
        );
    }, [isMobile, isTablet, renderMarketList]);

    return (
        <div className="flex flex-col w-full mt-12 md:mt-9 mx-[18px] sm:mx-6 lg:mx-0">
            <div className="mb-4">
                <LandingBlockHeader title={t('page.landing.marketStats.title')} />
            </div>
            {renderMarketStats}
        </div>
    );
};
